import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
import axios from 'axios';
export const store = new Vuex.Store({
  state: {
    pn_clientcode: 0,
    pn_needpin: 0,
    pn_clientname: 'ATK_Klantnaam',
    pn_clientimagename: '',
    pn_clientimageattr: '',
    pn_smallimage: '/images/ico/favicon.ico', //'/images/hermans.png'
    c_pn_imgdirectory: '/images/'
  },
  getters : {
	GET_CLIENTNAME(state) {
  	  return state.pn_clientname;
  	},
  	GET_CLIENTSMALLIMAGE(state) {
  	  return state.pn_smallimage;
  	},
  	GET_CLIENTIMAGEATTR(state) {
  	  return state.pn_clientimageattr;
  	},
  	GET_CLIENTCODE(state){
  	  let vm=this;
  	  if(state.pn_clientcode == 0){
  	    let feedUrl=secUrl("/verslag/pub_verslag_ajax.fetchC");
        console.log(feedUrl);
        //startLoad();
        axios.get(feedUrl)
         .then(function (response) {
           //stopLoad();
           response.data.data.forEach(function(r){
        	 //console.log(r);
        	 let cc=parseInt(r.c,10);
        	 //console.log(cc);
        	 if(cc>0)
        	   state.pn_clientcode=cc;
        	 else
        	   console.log("c not resolved.")
        	 if(r.hasOwnProperty("needpin")
        	 && parseInt(r.needpin,10) == 1
        	   )state.pn_needpin=1;
        	 else
        	   state.pn_needpin=0;
        	 if(r.hasOwnProperty("cname"))
        	   state.pn_clientname=r.cname;
        	   //vm.$store.dispatch('setClientInfo',cname);
        	 if(r.hasOwnProperty("cimgname")){
        	   state.pn_clientimagename=r.cimgname;
        	   if(state.pn_clientimagename.length>0)
        		 state.pn_smallimage = state.c_pn_imgdirectory + state.pn_clientimagename;
        	 }
        	 if(r.hasOwnProperty("cimgattr"))
        	   state.pn_clientimageattr=r.cimgattr;
        	 //console.log("state.pn_clientimagename = "+state.pn_clientimagename);
        	 //console.log("state.pn_clientimageattr = "+state.pn_clientimageattr);
           })
          })
         .catch(function (error) {
           //stopLoad();
           console.log(error);
	      });
      }
  	  console.log("state.pn_clientcode = "+state.pn_clientcode)
      return state.pn_clientcode;
  	},
  	GET_NEEDPIN(state){
  	  return state.pn_needpin;
  	}
  },
  mutations : {
	SET_CLIENTNAME(state,value) {
	  let properval=value.replace(/dev$/,'');
      state.pn_clientname = properval;
  	},
  	SET_CLIENTSMALLIMAGE(state,value) {
  	  let properval=value.toLowerCase().replace(/dev$/,'');
  	  state.pn_smallimage = state.c_pn_imgdirectory + (state.pn_clientimagename || properval+'.png');
    }
  },
  actions: {
	// this.$store.dispatch('setClientname',value);
	setClientInfo(state,value){
	  //console.log('SET_CLIENTNAME => '+value);
  	  state.commit('SET_CLIENTNAME',value);
  	  state.commit('SET_CLIENTSMALLIMAGE',value);
  	},
  }
});
