<template>
  <div class="container">
  <div class="starter-template">
  <h1>{{pn_clientname}} - ATK <span v-html="$gettext('materialen')" /></h1>
  <p v-if="pn_lang=='fr_BE'"> <a href="http://www.atk.be/p_contact_fr?lcid=fr">www.atk.be</a> </p>
  <p v-else>                  <a href="http://www.atk.be/p_contact_nl?lcid=nl">www.atk.be</a> </p>
  <p v-if="i_hideemail==0"> atk@atk.be </p>
  <p v-if="i_hideemail==1"> atk [at] atk.be </p>
  <p> tel. 015 55 51 51 </p>
  <p>&copy; ATK  - {{aboutyear}}</p>
  </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aboutyear: (new Date()).getFullYear(),
      i_hideemail: 2
    }
  },
  computed: {
	pn_clientname: function() {
	  return this.$store.getters.GET_CLIENTNAME;
	},
	pn_lang: function() {
	  return this.$language.current;
	}
  },
  methods: {
  }
}
</script>
<style>
</style>
