<template>
<div>
  <!--
  <nav>
  <router-link to="/">Home</router-link>
  <router-link to="/about">About</router-link>
  </nav>
  -->
  <topnavbar/>
  <section>
  <router-view/>
  </section>

</div>
</template>
<script>
import topnavbar from './router/Topnavbar.vue'
export default {
  components: {
    topnavbar // register component
  },
  data() {
    return {
      addressee: "earthling"
    }
  },
  methods: {
  },
  mounted() {
	let cname = "demo";
	if(window.location.host.split(':')[0].indexOf('.')>0)
	  cname = window.location.host.split(':')[0].split('.')[0];
	else
	  console.log("hostname = "+window.location.host.split(':')[0]+" => cname = "+cname);
	cname=cname.initCap();
	this.$store.dispatch('setClientInfo',cname);
	if(document.title.indexOf(cname + " - ")<0)
	  document.title = cname + " - " + document.title;
  }
}
</script>
<style>
</style>
