<template>
	
<section>

<div class="container" style="max-width:400px; !important">

<div v-if="pn_pinneeded!=1">
<div>
  <p/>
  <p v-html="$gettext('Vul hier de code in van het materiaal dat u wilt opzoeken.')" />
</div>
<form role="form" id="f">
  <div class="form-group">
   <!-- <label for="f-fnr"><translate>Firma-nummer van materiaal</translate></label> -->
   <label for="f-fnr" v-html="$gettext('Firma-nummer van materiaal')" />
   <input id="f-fnr"
	      type="text"
	      v-model="f_fnr"
	      ref="f_fnr"
	      class="form-control"
	     :placeholder="$gettext('Firma-nummer')"
	      required="required"
	      autofocus="autofocus"
	     >
  </div>
  <button id="f-btn"
	      class="btn btn-lg btn-primary btn-block"
	      type="submit"
	     @click="execQuery"
	      >
	<span v-html="$gettext('Zoek')" />
  </button>
</form>
</div> <!-- pn_pinneeded 0 -->

<div v-if="pn_pinneeded==1">
<div class="text-center">
  <p/>
  <p v-html="$gettext('Gelieve een pincode in te vullen')+'.'" />
</div>
<form role="form" id="p">
 <div class="form-group text-center">
  <div class="row">
  <div class="col-xs-4"/>
  <div class="col-xs-4">
  <input id="f-pin"
	     type="password" pattern="[0-9]{4}" inputmode="numeric"
	     size="4"
	     maxlength="4"
	     v-model="f_pin"
	     ref="f_pin"
	     class="form-control"
	    :placeholder="$gettext('PIN')"
	     required="required"
	     autofocus="autofocus"
	     style="{font-size:20px}"
	    >
  </div>
  <div class="col-xs-4"/>
  </div> <!-- row -->
 </div>  <!-- form-group -->
 <button id="f-btn"
	     class="btn btn-lg btn-primary btn-block"
	     type="submit"
	    @click="execAuth"
	     >
  <span v-html="$gettext('Verstuur')" />
 </button>
</form>
<!--<div class="debug-show">pin {{f_pin}}</div>-->
</div> <!-- pn_pinneeded 1 -->

<div v-if="q_pin_warn.length>0"
	 class="col-xs-12 text-center"
	 style="height:5px;"
	 >
</div>
<div v-if="q_pin_warn.length>0"
	 class="col-xs-12 text-center"
	 >
 <button id="pinwarn"
	     class="btn btn-danger"
	    @click="doWarn('');f_pin='';$('#f-pin').focus();"
	    >
  <span v-html="$gettext(q_pin_warn)" />
 </button>
</div>

</div> <!-- /container -->

<div v-if="pn_pinneeded==0"
	 id="resultDiv"
	 class="container hide"
     style="max-width:400px; !important"
     >
<table id="resultTable" class="table table-striped">
<thead>
  <tr>
      <th><span v-html="$gettext('Verslag')" /></th>
      <th><span v-html="$gettext('Datum')" /></th>
      <th><span v-html="$gettext('Inhoud')" /></th>
  </tr>
</thead>
<tbody></tbody>
</table>
</div> <!-- /container -->


<div id="loading-indicator" class="loading-indicator" style="display:none;">
<img src="/images/loading.gif" id="loading-indicator">
</div>

<div class="container" style="max-width:400px; !important">
 <hr/>
 <table width="100%">
 <tr><td width="20%">
 <img id="footerimg"
	 :src="pn_clientsmallimage"
     />
 </td><td id="env" align="center" class="normal"></td><td width="20%" align="right">
 <img src="/images/ico/favicon.ico"/>
 </td></tr>
 </table>
</div>

<span class="debug">pn_clientname = {{pn_clientname}}</span>
<span class="debug">pn_clientcode = {{pn_clientcode}}</span>

</section>

</template>
<script>
import axios from 'axios';
export default {
  name: 'psearch',
  data () {
    return {
      f_fnr: "",
      f_pin: "",
      q_pin_ack: 0,
      q_pin_warn: "",
      qminsec: 1, // minimum sec needed between 2 requests
      qtime: new Date() // time of (last) query
    }
  },
  computed: {
	pn_clientname: function() {
	  return this.$store.getters.GET_CLIENTNAME;
    },
    pn_clientcode: function() {
	  return this.$store.getters.GET_CLIENTCODE;
	},
	pn_pinneeded: function() {
	  let vm=this;
	  let need=this.$store.getters.GET_NEEDPIN == 1
	        && this.q_pin_ack == 0
	         ? 1
	         : 0 ;
	  //werkt ook niet
	  //if(need==1)
		//setTimeout(function(){$('#f-pin').pincodeInput({
		  //inputs:4,
		  //change: function(input,value,inputnumber){
	      //while(vm.f_pin.length<inputnumber-1)vm.f_pin+=" ";
		  //if(vm.f_pin.length==inputnumber-1)vm.f_pin+=value;
			//else vm.f_pin=vm.f_pin.substring(0,inputnumber-1)+value+vm.f_pin.substring(inputnumber);
		  //}
		  // hieronder werkt allemaal niet
	  	    // value = the entered code
	  	    // e = last keyup event
	  	    // errorElement = error span next to to this, fill with html
     	    // e.g. : $(errorElement).html("Code not correct");
		  //complete:function(value,e,errorElement){
		  //	vm.f_pin=value;}  
		  //});},50);
      return need;
	},
	pn_clientsmallimage: function() {
	  return this.$store.getters.GET_CLIENTSMALLIMAGE;
	},
	pn_clientimageattr: function() {
	  let res = this.$store.getters.GET_CLIENTIMAGEATTR;
	  //console.log("pn_clientsmallimage = "+this.pn_clientsmallimage+" =? "+this.$store.getters.GET_CLIENTSMALLIMAGE);
	  //console.log("pn_clientimageattr = "+res);
	  if(res.length>0){
		//console.log(res);
		let ares=JSON.parse(("{"+res+"}").replace(/=/g,':').replace(/ /g,',').replace("width",'"width"').replace("height",'"height"'));
		//console.log(ares);
	    $("#footerimg").attr(ares);
	  }
	  return res;
	}
  },
  methods: {
    activateFocus() {
      if(this.pn_pinneeded==1){
        this.$refs.f_pin.focus();
      }else
        this.$refs.f_fnr.focus();
    },
    doWarn: function(w){
      this.q_pin_warn=w;
    },
    makeAjaxRequest: function(p_fnr) {
      let vm=this;
  	  let feedUrl=secUrl("/verslag/verslag_fn_ajax"
  		  +"?fn_query="+encodeURIComponent(p_fnr)
  		  +"&fs_cust=" +encodeURIComponent(vm.pn_clientcode)
  		  +"&fx_hash=" +encodeURIComponent(makeAjaxHash(vm.pn_clientcode+(this.$store.getters.GET_NEEDPIN==1?"/"+vm.f_pin:"")))
  		  +"&lan="     +encodeURIComponent(this.$language.current.substring(0,2).toUpperCase())
  		  ,true);
  	  //console.log("makeAjaxRequest");
      console.log(feedUrl);
      //console.log("fx_hash from ");
      //console.log(vm.pn_clientcode+(this.$store.getters.GET_NEEDPIN==1?"/"+vm.f_pin:""));
      //console.log(feedUrl);
      startLoad();
      axios.get(feedUrl)
       .then(function (response) {
         stopLoad();
         console.log(response);
    	 $("#resultDiv").removeClass("hide");
	     $('table#resultTable tbody').html(response.data);
	     return true;
        })
       .catch(function (error) {
         stopLoad();
         console.log(error);
         return false;
	    });
      return false;
    },
    execQuery: function(event) {
      event.preventDefault();
   	  //alert("hi"); 
   	  //return;
      let vm=this;
      if(vm.f_fnr.replace(/ /g,"").length<1)return false;
      let qdiff=new Date(new Date() - vm.qtime);
      let qdiffsec=qdiff/1000;
      if(qdiffsec < vm.qminsec)return false;
      vm.qtime=new Date();
      let s=vm.makeAjaxRequest(vm.f_fnr);
    },
    makeAjaxAuthRequest: function(p_pin) {
      let vm=this;
  	  let feedUrl=secUrl("/verslag/verslag_fn_ajax"
    		  +"?ft_type=AUTH"
    		  +"&fs_cust=" +encodeURIComponent(vm.pn_clientcode)
    		  +"&fx_hash=" +encodeURIComponent(makeAjaxHash(vm.pn_clientcode+(this.$store.getters.GET_NEEDPIN==1?"/"+p_pin:"")))
    		  +"&lan="     +encodeURIComponent(this.$language.current.substring(0,2).toUpperCase())
    		  ,true);
  	  //console.log("makeAjaxAuthRequest");
      console.log(feedUrl);
      startLoad();
      axios.get(feedUrl)
       .then(function (response) {
         stopLoad();
         console.log(response);
         if(response.data.pinok==1){
          vm.q_pin_ack=1;
          vm.doWarn('');
          return true;
         }
         else{
          vm.doWarn("Wrong pin");
          return false;
         }
        })
       .catch(function (error) {
         stopLoad();
         console.log(error);
         return false;
      });
      return false;
    },
    execAuth: function(event) {
      event.preventDefault();
      let vm=this;
      if(vm.f_pin.replace(/ /g,"").length<1)return false;
      let qdiff=new Date(new Date() - vm.qtime);
      let qdiffsec=qdiff/1000;
      if(qdiffsec < vm.qminsec)return false;
      vm.qtime=new Date();
      let s=vm.makeAjaxAuthRequest(vm.f_pin);
      if(s)vm.q_pin_ack=1;
    }
  },
  mounted () {
	let vm=this;
    if(/.*dev\.atk\.be/.test(document.location.hostname.toLowerCase())
    || /localhost/     .test(document.location.hostname.toLowerCase())
      ){
	  $("#env").html('*** DEV ***');
	  $("#env").css("background-color","#FDCED8");
	}
    setTimeout(function(){vm.activateFocus();},125);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<IGNOREstyle scoped>
/*
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
*/
</IGNOREstyle>

