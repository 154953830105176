String.prototype.initCap = function () {
  return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
    return m.toUpperCase();
  });
};

import Vue from 'vue'

import Vuex from 'vuex'

import pnsearch from './pnsearch.vue'
import {store} from '../store/store.js'

import router from './router/index.js'

import GetTextPlugin from 'vue-gettext'
import translations from '../translate/translations.json'

Vue.use(GetTextPlugin, {
  availableLanguages: {
	nl_BE: 'Belgian Dutch',
    en_GB: 'British English',
    fr_BE: 'BE Français',
    it_IT: 'Italiano',
  },
  defaultLanguage: 'nl_BE',
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace('_', '-')
      },
    },
  },
  translations: translations//,
//  silent: True
});

//import modal from 'vue-js-modal'
//Vue.use(modal); //, { componentName: "modal" })
//Vue.use(modal); //, { componentName: "modal", pivotY: 0.1 });

//var Inputmask = require('inputmask');
//Vue.directive('inputmask', {
//	bind: function(el) {
//		new Inputmask().mask(el);
//	},
//});

new Vue({
  el: '#pnsearch',
  store,
  router,
  render: h => h(pnsearch)
});


//console.log("abc".initCap());
