import Vue from 'vue'
import Router from 'vue-router'
import Search from '../components/Search.vue'
import About from '../components/About.vue'
import Contact from '../components/Contact.vue'

//werkt ook niet//import BootstrapPincodeInput from 'bootstrap-pincode-input'

Vue.use(Router)

export default new Router({
  mode: 'history', // om "server/#/route" te vermijden in URL
  routes: [
    {
      path: '/',
      name: 'Search',
      component: Search
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    }
  ]
})
