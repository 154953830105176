<template>
<header>
 <div class="navbar navbar-inverse navbar-fixed-top" role="navigation">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="#">{{pn_clientname}} - <span v-html="$gettext('ATK materialen')" /></a>
        </div>
        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav">
            <li class="active"><router-link to="/"><translate>Home</translate></router-link></li>
            <li><router-link to="/about"><translate>Wat</translate></router-link></li>
            <li><router-link to="/contact"><translate>Contact</translate></router-link></li>
            <!-- 
            <li class="active"><a href="#">Home</a></li>
            <li><a href="/about.html">Wat</a></li>
            <li><a href="/contact.html">Contact</a></li>
             -->
          </ul>
          <div class="btn-group atk-lang" data-toggle="buttons-radio">
            <button name="languagePicker" id="lp-nl" value="nl" class="btn btn-mini active" type="submit" @click="toggleLan('nl_BE');">NL</button>
            <button name="languagePicker" id="lp-fr" value="fr" class="btn btn-mini" type="submit" @click="toggleLan('fr_BE');">FR</button>
            <button name="languagePicker" id="lp-en" value="en" class="btn btn-mini" type="submit" @click="toggleLan('en_GB');">EN</button>
          </div>
        </div><!--/.nav-collapse -->
      </div>
    </div>
</header>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
	pn_clientname: function() {
      return this.$store.getters.GET_CLIENTNAME;
    }
  },
  methods: {
    toggleLan: function(l){
      this.$language.current = l;
	  $("button[id^=lp-]").removeClass('active');
    }
  }
}
</script>
<style>
.atk-lang{
	font-size:20px;
}
</style>

}


