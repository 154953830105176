<template>
  <div class="container">

  <div>
    <h1>{{pn_clientname}} - ATK <span v-html="$gettext('materialen')" /></h1>
    <p><span v-html="$gettext('About_zin1_p1')" />
       {{pn_clientname}}
       <span v-html="$gettext('About_zin1_p2')" />.
    </p>
    <p>&copy; ATK  - {{aboutyear}} - <router-link to="/contact"><span v-html="$gettext('Contact')" /></router-link></p>
  </div>
  </div><!-- /.container -->
</template>
<script>
export default {
  data() {
    return {
      aboutyear: (new Date()).getFullYear()
    }
  },
  computed: {
	pn_clientname: function() {
	  return this.$store.getters.GET_CLIENTNAME;
	}
  },
  methods: {
  }
}
</script>
